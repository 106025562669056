@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
    margin: 0;
    background-color: #E1EBFB !important;
    width: 100%;
    height: 100%;
    font-family: "Poppins", sans-serif !important;
    font-style: normal;
    overflow-x: hidden; 
  }

  /* Style for WebKit browsers (Chrome, Safari, Edge) */
::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: #b6adb2; /* Color of the scrollbar */
  /* border-radius:5px;  */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #b6adb2; /* Color on hover */
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Background of the scrollbar track */
}


